import React from "react"

import "./modal.scss"

const Modal = ({ toggleModal, service, children, isOpen }) => (
    <>
        <div className={`modal ${isOpen ? "modal--open" : ""}`}>
            <div className="modal__content">
                <button onClick={toggleModal} className="modal__close">&times;</button>
                <div className="modal__header">
                    <h4 className="modal__title">Enquire about {service}</h4>
                    <p className="modal__subtitle">Fill in your details below and one of our team will contact you shortly.</p>
                </div>
                {children}
            </div>
        </div>
    </>
)

export default Modal
