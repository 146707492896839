import React from "react"

import "./hero-image.scss"

const HeroImage = (props) => (
  <div className="hero_image">
    <picture className="hero_image__picture-outer">
      <source srcSet={props.image} type="image/webp"></source>
      <source srcSet={props.image} type="image/jpeg"></source>
      <img src={props.image} alt="Hero" className="hero_image__picture-inner" />
    </picture>
    <div className="page-width">
      <div className="hero_image__inner" style={{ padding: `${props.padding}`}}>
        <span className="hero_image__pretitle">{props.subtitle}</span>
        <h1 className="hero_image__title">
          {
            props.title === "Marked Structural Steel"
            ?
            <><svg style={{marginRight: 15, marginTop: "-6px"}} xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 280 200" fillRule="evenodd" clipRule="evenodd"><path fill="#ffffff" d="M110 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 000 140 70 70 0 0010-.5zM280 199.5a100 100 0 01-10 .5 100 100 0 010-200 100 100 0 0110 .5v30a70 70 0 00-10-.5 70 70 0 00-68.38 55H260v30h-58.38A70 70 0 00270 170a70 70 0 0010-.5z"/></svg>{props.title}</>
            :
            props.title
          }
        </h1>
        <hr />
      </div>
    </div>
    <div className="hero_image__overlay"></div>
  </div>
)

export default HeroImage
