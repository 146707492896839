import React from "react"
import { navigate } from 'gatsby-link'
import "../Form/form.scss"

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

class ServicesPageForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isValidated: false,
            pageTitle: this.props.title,
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }

    render() {
        const formID = this.props.formID;
        return(
            <form
                id={formID}
                className="form service-form"
                name={`${formID}-service-form`}
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
            >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value={`${formID}-service-form`} />
                <div hidden>
                    <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                    </label>
                </div>
                {/* <div className="visually-hidden">
                    <input
                        id={'pagetile'}
                        name={'page-title'} 
                        type={'text'}
                        defaultValue={this.state.pageTitle}
                    />
                    <label htmlFor="pagetile">Referring Page</label>
                </div> */}
                <div className="form-label-group">
                    <input
                        id={'firstName'}
                        name={'first-name'}
                        type={'text'}
                        placeholder={'First name'}
                        required
                        onChange={this.handleChange}
                    />
                    <label className="form_label" htmlFor="firstName">First name</label>
                </div>
                <div className="form-label-group">
                    <input
                        id={'lastName'}
                        name={'last-name'}
                        type={'text'}
                        placeholder={'Last name'}
                        required
                        onChange={this.handleChange}
                    />
                    <label htmlFor="lasttName">Last name</label>
                </div>
                <div className="form-label-group">
                    <input
                        id={'company'}
                        name={'company'}
                        type={'text'}
                        placeholder={'Company'}
                        required
                        onChange={this.handleChange}
                    />
                    <label htmlFor="company">Company</label>
                </div>
                <div className="form-label-group">
                    <input
                        id={'email'}
                        name={'email'}
                        type={'email'}
                        placeholder={'Email'}
                        required
                        onChange={this.handleChange}
                    />
                    <label htmlFor="email">Email</label>
                </div>
                <div className="form-label-group">
                    <input
                        id={'telephone'}
                        name={'telephone'}
                        type={'tel'}
                        placeholder={'Telephone'}
                        required
                        onChange={this.handleChange}
                    />
                    <label htmlFor="telephone">Telephone</label>
                </div>
                <div className="form-label-group">
                    <textarea
                        id={'howcanwehelp'}
                        name={'howcanwehelp'}
                        placeholder={'How can we help?'}
                        required
                        onChange={this.handleChange}
                    />
                    <label htmlFor="howcanwehelp">How can we help?</label>
                </div>
                <input className="form__button" type="submit" value="Get free quote" />
            </form>
        );
    }
}

export default ServicesPageForm
