import React, { Component } from "react";
import Slider from "react-slick";

import "./slider-small.scss"

export default class SliderSmall extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the videoPlayer DOM element
    this.videoPlayer = React.createRef();
    // this.videoPlayerPlayButton = React.createRef();
  }

  state = {
    activeSlide: 0,
    activeSlide2: 0,
    nav1: null,
    nav2: null,
    autoplay: true,
    smallSliderImagesToShow: 2,
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    if(this.props.images.length === 1) {
      this.setState({
        smallSliderImagesToShow: 1,
      });
    }
  }

  playVideo = () => {
    // console.log(this.videoPlayer.current)
    // console.log(this.videoPlayerPlayButton.current)
    const video = this.videoPlayer.current;
    // const playerPlayCircle = this.videoPlayerPlayButton.current;
    this.setState({
      autoplay: false
    });
    if(video.paused) {
      // playerPlayCircle.style.opacity = 0;
      video.play();
    } else {
      video.pause();
      // playerPlayCircle.style.opacity = 1;
      this.setState({
        autoplay: true
      });
    }
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: this.state.autoplay,
      autoplaySpeed: 3500,
      touchThreshold:100,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current })
    };
    // console.log(this.state.activeSlide);
    // console.log(this.state.activeSlide2);
    const {
      images
    } = this.props;

    return (
      <>
      <div className="slider-small" style={{position: "relative"}}>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          className="slick-slider__small"
          {...settings}
        >

          {images &&
            images.map( image => (
            <div className="s_item__small" key={image}>
              {
                image.indexOf('.mp4') > -1
                ?
                <video controls ref={this.videoPlayer} className={`${this.state.activeSlide === 10 ? 'player__video--visible' : ''} player__video player__video--visible`} src={image}></video>
                :
                <img src={image} alt="" />
              }
            </div>
          ))}
        </Slider>

        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={this.state.smallSliderImagesToShow}
          swipeToSlide={true}
          focusOnSelect={true}
          className="slick-slider__small-squares"
        >
          {images &&
            images.map( image => (
            <div className="slick-slider__small-squares__item" key={image}>
              {
                image.indexOf('.mp4') > -1
                ?
                <video className="player__video--small player__video--visible" src={image}></video>
                :
                <span style={{ display: "block", backgroundImage: `url('${image}')`, backgroundPosition: "center center", height: "119px", backgroundSize: "cover" }}></span>
              }
            </div>
          ))}
        </Slider>

      </div>
      </>
    );
  }
}
