import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/layout'
import Content, { HTMLContent } from '../components/Content/Content'
import HeroImage from "../components/HeroImage/heroImage"
import Modal from '../components/Modal/Modal';
import FormFleet from '../components/FormService/FormService';
import SliderSmall from '../components/SliderSmall/SliderSmall';

import "../assets/sass/theme/pages/fleet-child.scss"

class FleetChildPageTemplate extends React.Component {
  state = {
    isOpen: false,
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { isOpen } = this.state;
    const {
      content,
      contentComponent,
      description,
      title,
      helmet,
      vehicleimage,
      manual_pdf,
      galleryImages
    } = this.props;

    const FleetChildContent = contentComponent || Content
    return (
      <>
      <HeroImage title={title} subtitle="Fleet" image={vehicleimage} position="center center" padding="150px 0" />
      <main>
        <div className="page-width">
          {helmet || ''}
          <div className="fleet-child">
            <div className="fleet__images">
              <SliderSmall images={galleryImages} />
            </div>
            <div className="fleet__content">
              <div className="fleet__content_inner">
                <div className="fleet__description-box">
                  <p><span className="fleet__description-title">Full description:</span> {description}</p>
                  <p><span className="fleet__description-title">Make:</span></p>
                  <p><span className="fleet__description-title">Max capacity:</span></p>
                </div>
                <FleetChildContent content={content} />
                {
                  manual_pdf &&
                    <a href={manual_pdf} className="button--brand button cta__button--download" style={{marginRight: "1rem"}}>
                      <svg className="download-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="download-icon" fill="#1a4870" d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/></svg>
                      Download Spec Sheet
                    </a>
                }
                <button className="button--brand button cta__button" onClick={this.toggleModal}>Hire now</button>
                {
                  isOpen &&
                  <Modal service={title} toggleModal={this.toggleModal} children={<FormFleet/>}/>
                }
              </div>
              {/* <Table /> */}
            </div>
          </div>
        </div>
      </main>
    </>
    )
  }
}

FleetChildPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const FleetChildPage = ({ data }) => {
  const { markdownRemark: post } = data
  //console.log(data)
  return (
    <Layout>
      <FleetChildPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Our Fleet">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        vehicleimage={post.frontmatter.vehicleimage}
        manual_pdf={post.frontmatter.manual_pdf}
        galleryImages={post.frontmatter.galleryImages}
      />
    </Layout>
  )
}

FleetChildPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default FleetChildPage

export const pageQuery = graphql`
  query FleetChildPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        vehicleimage
        description
        tags
        manual_pdf
        galleryImages
      }
    }
  }
`
